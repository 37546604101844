.App {
  text-align: left;
}

nav {
  text-align: left;
  background-color: rgb(222, 236, 198);
  color:rgb(19, 18, 18);
  padding-top: calc(5px + 1vmin);
  display: flex;
  /* flex-direction: column; */
  text-decoration: none;
  padding: 5px;
}

a {
  text-decoration: none;
  color:rgb(19, 18, 18)
}

a:visited {
  text-decoration: none;
  color:rgb(19, 18, 18)
}

a:hover {
  text-decoration: none;
  color:rgb(19, 18, 18)
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: rgb(19, 18, 18);
  padding: 40px;
}

.App-link {
  color: #61dafb;
}


.body-description {
  text-align: center;
  display: block;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgb(222, 236, 198);
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.description {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-size: 0.75em;
  font-weight: 300;
}

.description.extra {
  font-size: 0.6em
}

h2 {
  font-size: 1em;
}

h2, .pageHeader{
  font-size: 1.7em;
}

.large-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgb(222, 236, 198);
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#Overview {
  margin-bottom: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
